var Backbone = require('backbone');
var jQuery = require('jquery');
var mediator = require('../mediator');

module.exports = Backbone.Model.extend({

  alter: {
    controllers: function (controllers) {
      for (var i in this.controllers) {
        controllers[i] = this.controllers[i];
      }
    },

    dashboard: function (data) {
      data.eligibilityFiles = {
        fa: 'fa-group',
        page: 'dashboard/eligibilityFiles',
        text: "Eligibility Files",
        weight: 700
      };
    },

    routes: function (routes) {
      routes['dashboard/eligibilityFiles'] = {
        controller: 'dashboardEligibilityFiles',
        data: {
          title: "Manage Eligibility Files"
        },
        name: "dashboard/eligibilityFiles"
      };

      routes['dashboard/eligibilityFileEdit'] = {
        controller: 'dashboardEligibilityFileEdit',
        data: {
          title: "Add Eligibility File"
        },
        name: "dashboard/eligibilityFileEdit"
      };

      routes['dashboard/eligibilityFileEdit/:eligibilityFileID'] = {
        controller: 'dashboardEligibilityFileEdit',
        data: {
          title: "Edit Eligibility File"
        },
        name: "dashboard/eligibilityFileEdit"
      };

    }
  },

  attachEvents: function () {

  },

  controllers: {
    dashboardEligibilityFiles: function ($el, data) {
      var PageView = require('../views/dashboardEligibilityFiles');
      var view = new PageView(data);

      return view;
    },

    dashboardEligibilityFileEdit: function ($el, data) {
      var PageView = require("../views/dashboardEligibilityFileEdit");
      var view = new PageView(data);

      return view;
    }
  }

});