var Backbone = require('backbone');
var jQuery = require('jquery');
var mediator = require('../mediator');

module.exports = Backbone.Model.extend({
  attachEvents: function () {

    var self = this;

    this.listenTo(mediator, 'all', function (a, b, c) {
      this.$list.prepend("<li>" + a + "</li>");
      console.debug("DEBUG", a, b, c);
    });
  },

  debug: function () {
    this.$list.prepend("<li>" + Array.prototype.slice.call(arguments) + "</li>");
    console.log.apply(console, arguments);
  },

  error: function () {
    this.$list.prepend("<li>" + Array.prototype.slice.call(arguments) + "</li>");
    console.error.apply(console, arguments);
  },

  info: function () {
    this.$list.prepend("<li>" + Array.prototype.slice.call(arguments) + "</li>");
    console.log.apply(console, arguments);
  },

  initialize: function () {

    jQuery('body').append('<div id="debug"><div class="close">X</div><h5>Debug</h5><ul class="log"></ul></div>');
    jQuery("#debug .close").on("click", function () {
      jQuery("#debug").hide();
    });

    this.$list = jQuery('#debug .log');
  },

  mediatorAlter: function () {
    var self = this;

    mediator.error = function () {
      self.error.apply(self, arguments);
    };

    mediator.debug = function () {
      self.debug.apply(self, arguments);
    };

    mediator.log = function () {
      self.info.apply(self, arguments);
    };
  }

});