var BaseView = require('./questionBase');
var jQuery = require('jquery');

module.exports = BaseView.extend({

  afterRender: function () {

    if (this.options.response) {
      this.$el.find('button')
          .removeClass('active');
      this.$el.find('button[data-value="' + this.options.response.response + '"]')
          .addClass('active')
          .prepend('<span class="fa fa-2x fa-fw fa-check"></span>');
    }
  },

  beforeRender: function () {
    if (this.options.question.optionColumns === undefined ||
        this.options.question.optionColumns === null) {
      this.options.question.optionColumns = Math.floor(12 / this.options.question.options.length);
      if (this.options.question.optionColumns < 3) {
        this.options.question.optionColumns = 3;
      }
    }
  },

  events: {
    "click .questionOption": function (e) {
      var val = jQuery(e.currentTarget)
          .attr('data-value');
      this.setValue(val);
    }
  },

  template: 'questionMultiple.mustache',

  val: function () {

    return this.$el.find('button.active')
        .attr('data-value');
  }
});
