var Backbone = require('backbone');
var mediator = require('../mediator');

var ParticipantModel = Backbone.Model.extend({
  initialize: function () {
    var self = this;
    this.on('change', function () {
      mediator.trigger('participant:change', self.attributes);
    });
  }
});

var participant = new ParticipantModel();

module.exports = Backbone.Model.extend({

  alter: {

    condition: function (data) {
      this.condition(data);
    },

    controllers: function (controllers) {
      controllers.participantEdit = this.controllers.participantEdit;
      controllers.participantSelect = this.controllers.participantSelect;
      controllers.participantVerify = this.controllers.participantVerify;
    },

    participant: function (ppt) {
      for (var i in participant.attributes) {
        ppt[i] = participant.attributes[i];
      }
    },

    routes: function (routes) {
      routes.participantEdit = {
        controller: 'participantEdit',
        data: {
          title: "Edit Participant"
        },
        name: 'participantEdit'
      };
    },

    response: function (response) {
      var responses = participant.get('responses');
      if(!responses || !responses[response.question]) {

        return;
      }
      for (var i in responses[response.question]) {
        response[i] = responses[response.question][i];
      }
    },

    responses: function(response) {
      var responses = participant.get('responses');
      for(var i in responses) {
        response[i] = responses[i];
        response[i][responses[i].status] = true;
      }
    }

  },

  attachEvents: function () {

    var self = this;

    this.listenTo(mediator, "response:set", function (data) {
      self.responseSet(data.questionName, data);
    });

    this.listenTo(mediator, "participant:clear", function () {
      participant.clear();
    });

    this.listenTo(mediator, "participant:load", function (participantID, callback) {
      mediator.api("participant/get/" + participantID, {}, function (err, returnData) {

        if (err) {

          return;
        }

        participant.clear();
        participant.set(returnData);

        if (callback) {
          callback(null);
        }
      });
    });


    this.listenTo(mediator, "participant:response:update", function(data) {
      // We received a response update from the server
      // Ignore responses that are not ours
      if(parseInt(data.participantID) !== parseInt(participant.get('participantID'))) {
        return;
      }
      self.setResponse(data.question, {
        response: data.response,
        status: data.status,
        comment: data.comment
      });
    });

    this.listenTo(mediator, "participant:set", function (data, callback) {
      participant.set(data);

      if (callback) {
        callback(null);
      }
    });
  },

  checks: {

    age: function (condition) {
      var age = participant.get('age');
      if(age >= condition.min) {
        if(age <= condition.max) {
          return true;
        }
      }
      return false;
    },

    gender: function (condition, data) {
      if (participant.get('gender') == condition.value) {

        return true;
      }

      return false;
    },

    response: function (condition, data) {
      var responses = participant.get('responses');
      var response = responses[condition.question];

      if (response) {

        for (var i in condition.values) {

          if (response.response == condition.values[i]) {

            return true;
          }
        }
      }

      return false;
    },

    responseMultiple: function (condition) {
      var responses = participant.get('responses');
      var response = responses[condition.question];

      if (response) {

        responses = response.response.split(";");
        for (var i in condition.values) {
          for(var j in responses) {
            if (responses[j] == condition.values[i]) {
              return true;
            }
          }
        }
      }
      return false;
    }
  },

  condition: function (data) {
    var i;
    var j;
    var checkResult;
    var conditionsCopy;

    if (!data) {
      data = {};
    }

    if (!data.conditions || data.conditions.length === 0) {
      data.success = true;

      return;
    }

    // We will make changes to condition data, clone it to not affect
    // original
    conditionsCopy = JSON.parse(JSON.stringify((data.conditions)));

    // Assume pass unless a check fails
    data.success = true;

    for (i in conditionsCopy) {

      // Combine triggered event data with scripted
      for (j in data) {
        conditionsCopy[i][j] = data[j];
      }

      // Allow for templating inside event data
      /*
       for (j in conditions[i]) {
       if (typeof conditions[i][j] === 'string') {
       //conditionsCopy[i][j] = Template.render(conditions[i][j], {});
       }
       }*/

      checkResult = {};

      if (this.checks[conditionsCopy[i].condition](conditionsCopy[i]) === false) {
        data.success = false;
      }
    }
  },

  controllers: {
    participantEdit: function ($el, data) {
      var PageView = require('../views/participantEdit');
      var view = new PageView(data);

      return view;
    },

    participantSelect: function ($el, data) {
      var PageView = require('../views/participantSearch');
      var view = new PageView(data);

      return view;
    },

    participantVerify: function ($el, data) {
      var PageView = require('../views/participantVerify');
      var view = new PageView(data);

      return view;
    }
  },

  responseSet: function (question, data) {
    var responses = participant.get('responses');
    if(!responses) {
      responses = {};
    }
    if(!responses[question] ||
        data.response !== responses[question].response) {
      responses[question] = data;
      participant.set('responses', responses);
      mediator.trigger("response:change", responses[question]);
      mediator.trigger("response:change:" + question, responses[question]);
    }
  }
});