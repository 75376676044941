var BaseView = require('./base');
var mediator = require('../mediator');
var DetailView = require('./dashboardJobDetail');

module.exports = BaseView.extend({

  events: {
    "click [data-view]": function() {
      mediator.trigger("modal:view", {
        view: new DetailView(this.options)
      });
    }
  },

  template: 'dashboardJobsItem.mustache'
});