var BaseView = require('./base');
var mediator = require('../mediator');

module.exports = BaseView.extend({

  afterRender: function () {
    var self = this;

    if (this.options.debugCount <= 0) {
      this.$el.find('.debug').show();
    }
  },

  beforeRender: function () {
    if (!this.options.debugCount) {
      this.options.debugCount = 5;
    }

    this.options.screening = mediator.alter('screening');
    this.options.participant = mediator.alter('participant');
    this.options.version = mediator.alter('version');

    if (!this.timeout) {
      this.update();
    }
  },

  events: {
    'click .debugTarget': function () {
      this.options.debugCount--;

      if (this.options.debugCount == 0) {
        clearTimeout(this.timeout);
        this.$el.find('.debug').show();
      }
    },

    'click .debugUpdate': function () {
      var customer = this.$el.find('[name=customer]').val();
      var environment = this.$el.find('[name=environment]').val();

      mediator.api('server/debug', {
        customer: customer,
        environment: environment
      }, function (err, data) {
        mediator.trigger('toast:success', 'Force update started...');
      });
    }
  },

  template: 'dashboardStatus.mustache',

  update: function () {

    var self = this;
    clearTimeout(self.timeout);
    mediator.api('server/status', {}, function (err, returnData) {
      self.options.serverData = returnData;
      self.timeout = setTimeout(function () {
        self.update();
      }, 10000);

      self.render();
    });
  }
});