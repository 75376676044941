var BaseView = require('./base');
var mediator = require('../mediator');

var InputView = require('./input');

module.exports = BaseView.extend({
  events: {
    "click button": function(e) {
      var password = this.$el.find('[name=password]')
          .val();
      this.$el.find('[name=password]')
          .val('');
      mediator.trigger("toast:info", "Attempting to activate database...");
      mediator.api('database/activate', {
        password: password
      }, function(err, returnData) {
        if (err) {
          mediator.trigger("toast:warning", err);
        } else {
          mediator.trigger("toast:success", "Database activated, waiting for database to be loaded...");
          mediator.trigger("screening:set", {
            "databaseActivated": true
          });
          //mediator.trigger("page:home");
          // Reload screening settings
        }
      });
    }
  },

  initialize: function() {
    var self = this;
  },

  template: 'activateDatabase.mustache',
  views: {
    ".password": new InputView({
      autocorrect: "off",
      default: "",
      label: 'account.password',
      name: "password",
      placeholder: "",
      required: false,
      type: "password",
      value: ""
    })
  }
});