var BaseView = require('./questionBase');
var mediator = require('../mediator');

module.exports = BaseView.extend({
  afterRender: function () {

    if (this.options.response &&
        this.options.response.status) {
      this.$el.find('span[data-status="' + this.options.response.status + '"]')
          .show();
      this.$el.find('input').attr('data-status', this.options.response.status);
    }

    if (this.options.response) {
      this.$el.find('button')
          .removeClass('active');
      this.$el.find('button[data-value="' + this.options.response.response + '"]')
          .addClass('active')
          .prepend('<span class="fa fa-fw fa-check"></span>');
    }
  },

  events: {
    "keydown input": function (e) {
      if (e.keyCode === 13) {
        mediator.trigger("page:next");
      }
    },

    "change input": function () {
      this.setValue(this.$el.find('input').val());
    },

    "click .questionOption": function (e) {
      var val = jQuery(e.currentTarget)
          .attr('data-value');
      this.setValue(val);
    }
  },

  template: 'questionNumeric.mustache',

  val: function () {

    return this.$el.find('input').val();
  }

});
