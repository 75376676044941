var BaseView = require('./questionBase');
var jQuery = require('jquery');
var mediator = require('../mediator');

module.exports = BaseView.extend({

  afterRender: function () {
    var self = this;
    this.canvas = this.$el.find('canvas')[0];
    this.context = this.canvas.getContext('2d');

    if (this.options.response.response && this.options.response.response !== "") {
      this.everSaved = true;
      this.image = new Image();
      this.image.src = this.options.response.response;
      this.image.onload = function () {
        self.context.drawImage(self.image, 0, 0);
      };
    } else {
      this.clear();
    }
  },

  clear: function () {
    this.context.clearRect(0, 0, this.canvas.width, this.canvas.height);
    this.context.fillStyle = 'black';
    this.context.lineWidth = 15;
    this.$el.find('[data-save] .required').hide();
    this.everDrawn = false;
    this.everSaved = false;
    this.saved = true;
  },

  draw: function (x, y, e) {
    var self = this;

    if (self.drawing) {
      var setWidth = parseInt(jQuery(e.currentTarget).attr('width'), 10);
      var actualWidth = jQuery(e.currentTarget).width();

      // Get the mouse position in the canvas, adjusted for scaling
      x = x * (setWidth / actualWidth);
      y = y * (setWidth / actualWidth);

      if (!self.lastX) {
        self.lastX = x;
        self.lastY = y;
      } else {
        self.$el.find('[data-save] .required').show();
        self.saved = false;

        // Draw line from last point to this one
        self.context.lineWidth = 5;
        self.context.beginPath();
        self.context.moveTo(self.lastX, self.lastY);
        self.context.lineTo(x, y);
        self.context.stroke();
        self.lastX = x;
        self.lastY = y;
        self.everDrawn = true;
      }
    }
  },

  events: {
    "click [data-clear]": function () {
      this.clear();
    },

    "click [data-save]": function () {
      this.save();
    },

    "mousedown canvas": function () {
      this.startDrawing();
    },

    "mouseup canvas": function () {
      this.stopDrawing();
    },

    "mouseout canvas": function () {
      this.stopDrawing();
    },

    "mousemove canvas": function (e) {
      this.draw(e.offsetX, e.offsetY, e);
    },

    "touchstart canvas": function () {
      mediator.log("touchstart canvas");
      this.startDrawing();
    },

    "touchend canvas": function () {
      mediator.log("touchend canvas");
      this.startDrawing();
    },

    "touchmove canvas": function (e) {
      mediator.log("touchmove canvas");
      e.preventDefault();

      // get the touch coordinates.  Using the first touch in case of multi-touch
      var x = e.originalEvent.targetTouches[0].clientX;
      var y = e.originalEvent.targetTouches[0].clientY;

      var rect = this.canvas.getBoundingClientRect();
      x = x - rect.left;
      y = y - rect.top;

      this.draw(x, y, e);
    }
  },

  initialize: function () {
    this.drawing = false;
    this.everDrawn = false;
    this.everSaved = false;
    this.image = null;
    this.saved = true;
    this.lastX = null;
    this.lastY = null;
  },

  save: function () {

    if (this.everDrawn === false) {
      mediator.trigger("toast:warning", "Finger signature is required.");

      return;
    }

    this.$el.find('[data-save] .required').hide();
    this.saved = true;
    this.everSaved = true;
    this.setValue(this.canvas.toDataURL());
  },

  startDrawing: function () {
    this.drawing = true;
    this.lastX = null;
    this.lastY = null;
  },

  stopDrawing: function () {
    this.drawing = false;
    this.lastX = null;
    this.lastY = null;
  },

  template: 'questionSignature.mustache',

  validate: function () {

    if (this.saved === false) {
      this.$el.addClass('invalid');
      this.shake();
      mediator.trigger("toast:warning", "Finger signature has not been saved.");

      return false;
    }
    /*
     if (this.everSaved === false) {
     this.$el.addClass('invalid');
     this.shake();
     mediator.trigger("toast:warning", "Finger signature is required.");

     return false;
     }*/

    if (this.pending === true) {
      mediator.trigger("toast:warning",
          "Please wait, your response for \"" + this.options.question.title + "\" has not been saved yet.");

      return false;
    }

    if (this.options.question.required === true) {

      if (this.everSaved === false) {
        this.$el.addClass('invalid');
        this.shake();
        mediator.trigger("toast:warning", "Please enter a response for \"" + this.options.question.title + "\".");

        return false;
      }
    }

    return true;
  }
});