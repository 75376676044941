var BaseView = require('./base');

module.exports = BaseView.extend({

  events: {
    click: function () {
      this.trigger("click", this.options.participantID);
    }
  },

  template: 'participantSearchListItem.mustache'
});