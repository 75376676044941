var BaseView = require('./base');
var Moment = require('moment');
var mediator = require('../mediator');

module.exports = BaseView.extend({
  afterRender: function () {
    if (this.config &&
        this.config.edit == false) {
      this.$el.find('button[data-editProfile]')
        .hide();
    }
  },

  beforeRender: function () {
    this.options = mediator.alter('participant');
  },

  events: {
    'click [data-editProfile]': function () {
      window.location.hash = '#participantEdit';
    }
  },

  template: 'participantVerify.mustache'
});