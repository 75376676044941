var BaseView = require('./questionBase');
var mediator = require('../mediator');

module.exports = BaseView.extend({
  afterRender: function () {
  },

  events: {
    "keydown input": function (e) {
      if (e.keyCode === 13) {
        mediator.trigger("page:next");
      }
    },

    "change input": function () {
      this.setValue(this.$el.find('input').val());
    }
  },

  template: 'questionText.mustache',

  val: function () {
    return this.$el.find('input').val();
  }

});