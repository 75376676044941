var BaseView = require('./base');
var mediator = require('../mediator');
var Moment = require('moment');

module.exports = BaseView.extend({
  afterRender: function () {

    // Fix birthdate since format we use for display does not match format
    // needed by date field
    if (this.options.birthdate) {
      //var date = this.options.birthdate;
      var date = Moment(this.options.birthdate, 'YYYY-MM-DD')
        .local()
        .format('YYYY-MM-DD');
      this.$el.find('[name=birthdate]')
        .val(date);
    }

    if (this.options.state) {
      this.$el.find('[name=state]')
        .val(this.options.state);
    }

    if (this.options.gender) {
      this.$el.find('[name=gender][value=' + this.options.gender + ']')
        .click();
    }
  },

  beforeRender: function () {
    this.options = mediator.alter('participant');
  },

  events: {
    'click [data-cancel]': function () {
      window.history.back();
    },

    'click [data-save]': function (e) {
      this.save();
    }
  },

  save: function () {
    var self = this;
    if(self.isSaving) {
      return;
    }
    self.isSaving = true;

    var vals = this.$el.find('form')
      .serializeArray();
    var data = {};

    for (var i in vals) {
      data[vals[i].name] = vals[i].value;
    }

    // validations
    var errors = false;

    if (data.firstName === '') {
      errors = true;
      mediator.trigger('toast:warning', 'First name is required');
    }

    if (data.lastName === '') {
      errors = true;
      mediator.trigger('toast:warning', 'Last name is required');
    }

    if (!data.gender || data.gender === '') {
      errors = true;
      mediator.trigger('toast:warning', 'Gender is required');
    }

    if (data.birthdate === '') {
      errors = true;
      mediator.trigger('toast:warning', 'Birth Date is required');
    }

    if (data.uniqueID === '') {
      errors = true;
      mediator.trigger('toast:warning', 'Unique ID is required');
    }

    // Add hook to allow custom module to add extra validation
    data = mediator.alter('participant:edit:validate', data);
    if(data.errors === true) {
      errors = true;
    }
    delete(data.errors);

    if (errors === false) {

      if (data.participantID === '') {
        mediator.api('participant/create', data, function (err, returnData) {

          if (err) {
            mediator.trigger('toast:error', 'Error adding participant: {{ t "' + err + '"}}');
            self.isSaving = false;
            return;
          }

          mediator.api('participant/register', {
            participantID: returnData.participantID
          }, function (err, returnData) {

            if (err) {
              mediator.trigger('toast:error', 'Error registering participant: {{ t "' + err + '"}}');
              self.isSaving = false;
              return;
            }

            mediator.trigger('toast:success', 'Participant added successfully.');
            self.isSaving = false;
            window.history.back();
          });

        });
      } else {
        mediator.api('participant/update/' + data.participantID, data, function (err, returnData) {

          if (err) {
            mediator.trigger('toast:error', 'Error updating participant: {{ t "' + err + '"}}');
            self.isSaving = false;
            return;
          }

          mediator.trigger('participant:set', returnData);
          mediator.trigger('toast:success', 'Participant updated successfully.');
          self.isSaving = false;
          window.history.back();

        });
      }
    } else {
      self.isSaving = false;
    }
  },

  template: 'participantEdit.mustache'
});
