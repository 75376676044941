var BaseView = require('./base');
var mediator = require('../mediator');

module.exports = BaseView.extend({
  afterRender: function () {
    if (this.options.screening.active === false) {
      this.$el.find('.active')
          .hide();
    } else {
      this.$el.find('.noActive')
          .hide();
    }
  },

  beforeRender: function () {
    this.options.screening = mediator.alter('screening');
  },

  events: {
    "click .conclude": function () {
      mediator.trigger("modal:confirm", {
        text: 'Are you sure you want to conclude this screening? Concluding a screening will log all users out of the system.',
        title: 'Screening conclude confirmation',
        buttons: [
          'Conclude',
          'Cancel'
        ]
      }, function (err, buttonText) {
        if(buttonText === 'Conclude') {
          mediator.api("screening/conclude", {});
        }
      });
    }
  },

  template: 'dashboardMiniCurrent.mustache'
});