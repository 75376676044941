var Backbone = require('backbone');
var jQuery = require('jquery');
var mediator = require('../mediator');
var FooterView = require('../views/footer');

var workflow = new Backbone.Model();

module.exports = Backbone.Model.extend({

  alter: {
    controllers: function (controllers) {
      for (var i in this.controllers) {
        controllers[i] = this.controllers[i];
      }
    },

    dashboard: function (data) {
      data.currentScreening = {
        fa: 'fa-dashboard',
        page: 'dashboard/current',
        text: "Current Screening",
        view: "dashboardMiniCurrent",
        weight: 100
      };

      data.screenings = {
        fa: 'fa-folder',
        page: 'dashboard/screenings',
        text: "Screenings",
        weight: 200
      };

      data.eligibilityFiles = {
        fa: 'fa-group',
        page: 'dashboard/eligibilityFiles',
        text: "Eligibility Files",
        weight: 700
      };
    },

    question: function (data) {
      var question = this.get('questions')[data.name];

      for (var i in question) {
        data[i] = question[i];
      }
    },

    questions: function (data) {
      for (var i in this.attributes.questions) {
        data[i] = this.attributes.questions[i];
      }
    },

    page: function (pageData) {
      var page = this.get("pages")[pageData.name];
      var i;

      for (i in page) {
        pageData[i] = page[i];
      }

      // Combine workflow settings
      var workflow = this.get("workflow")
        .pageFlow[pageData.name];

      for (i in workflow) {
        pageData[i] = workflow[i];
      }
    },

    routes: function (routes) {
      routes['activateDatabase'] = {
        controller: 'activateDatabase',
        data: {
          title: "Activate Database"
        },
        name: "activateDatabase"
      };

      routes['dashboard/current'] = {
        controller: 'dashboardCurrent',
        data: {
          title: "Current Screening"
        },
        name: "dashboard/current"
      };

      routes['dashboard/screenings'] = {
        controller: 'dashboardScreenings',
        data: {
          title: "Manage Screenings"
        },
        name: "dashboard/screenings"
      };

      routes['dashboard/screeningEdit'] = {
        controller: 'dashboardScreeningEdit',
        data: {
          title: "Add Screening"
        },
        name: "dashboard/screeningEdit"
      };

      routes['dashboard/screeningEdit/:screeningID'] = {
        controller: 'dashboardScreeningEdit',
        data: {
          title: "Edit Screening"
        },
        name: "dashboard/screeningEdit"
      };

      routes.noActiveScreening = {
        controller: 'templatePage',
        data: {
          title: "No Currently Active Screening",
          config: {
            templateFile: "defaultNoActiveScreening.mustache"
          }
        },
        name: "noActiveScreening"
      };
    },

    screening: function (data) {
      for (var i in this.attributes) {
        data[i] = this.get(i);
      }
    }
  },

  attachEvents: function () {
    var self = this;

    this.listenTo(mediator, "participant:change", function (pptData) {
      self.footer.options.participant = pptData;
      self.footer.render();
    });

    this.listenTo(mediator, "page:home", function () {

      if (self.get('databaseActivated') === false) {
        mediator.trigger("page:show", "activateDatabase");
      } else {
        if (self.has('workflow') === true) {
          mediator.trigger("page:show", self.get('workflow')
            .home);
        } else {
          mediator.trigger("page:show", "noActiveScreening");
        }
      }
    });

    this.listenTo(mediator, "page:change", function (pageName) {
      self.set('currentPage', pageName);
    });

    this.listenTo(mediator, "screening:set", function (data) {
      if (!data) {
        self.clear();
        self.set('active', false);
      } else {
        self.set(data);
        if (data.screeningID) {
          self.set('active', true);
        } else {
          self.set('active', false);
        }
      }
      self.footer.options.screening = self.attributes;
      self.footer.render();
    });
  },

  controllers: {
    activateDatabase: function ($el, data) {
      var PageView = require('../views/activateDatabase');
      var view = new PageView(data);

      return view;
    },

    dashboardCurrent: function ($el, data) {
      var PageView = require('../views/dashboardCurrent');
      var view = new PageView(data);

      return view;
    },

    dashboardScreenings: function ($el, data) {
      var PageView = require('../views/dashboardScreenings');
      var view = new PageView(data);

      return view;
    },

    dashboardScreeningEdit: function ($el, data) {
      var PageView = require('../views/dashboardScreeningEdit');
      var view = new PageView(data);

      return view;
    },

    question: function ($el, data) {
      var PageView = require('../views/questionPage');
      var view = new PageView(data);

      return view;
    },

    registrationDisclaimer: function ($el, data) {
      var PageView = require('../views/registrationDisclaimerPage');
      var view = new PageView(data);

      return view;
    },

    resultsReview: function ($el, data) {
      var PageView = require('../views/resultsReviewPage');
      var view = new PageView(data);

      return view;
    },

    stationSelect: function ($el, data) {
      var PageView = require('../views/stationSelectPage');
      var view = new PageView(data);

      return view;
    },

    templatePage: function ($el, data) {
      var PageView = require('../views/templatePage');
      var view = new PageView(data);

      return view;
    }
  },

  start: function () {
    this.footer = new FooterView();
    jQuery("footer")
      .append(this.footer.$el);
    this.footer.render();
  }
});