var BaseView = require('./base');
var Backbone = require('backbone');
var jQuery = require('jquery');
var mediator = require('../mediator');

module.exports = BaseView.extend({

  afterRender: function () {
  },

  beforeRender: function () {
  },

  events: {
    "click button": function(e) {
      var page = jQuery(e.currentTarget).attr('data-page');
      mediator.trigger("page:show", page);
    }
  },

  initialize: function () {
  },

  template: 'stationSelectPage.mustache'
});