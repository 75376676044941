var Backbone = require('backbone');
var mediator = require('../mediator');
var jQuery = require('jquery');

var RouterClass = Backbone.Router.extend({

  // Page must be coming from pageFlow, not module
  default: function () {
    var self = this.self;
    self.pageName = window.location.hash.substring(1);

    if (self.pageName === '') {

      return;
    }

    self.showPage(self.pageName, [], function (view) {
    });
  },

  routes: {
    "*notFound": "default"
  }
});

module.exports = Backbone.Model.extend({

  attachEvents: function () {

    var self = this;

    this.listenTo(mediator, "page:next", function () {

      if (self.currentPage) {

        // Check validation to make sure we can change pages
        if (self.currentPage.view.validate) {

          if (self.currentPage.view.validate() === false) {

            return;
          }
        }
      }

      if (self.currentPage.options.confirmation) {

        // Prompt user for confirmation to continue
        mediator.trigger("modal:confirm", {
          text: self.currentPage.options.confirmation.text,
          title: self.currentPage.options.confirmation.title,
          buttons: [
              'Cancel',
              'Continue'
          ]
        }, function (err, buttonText) {

          if (buttonText === "Continue") {
            mediator.trigger("page:show", self.currentPage.options.next.page);
          } else {
            return;
          }
        });

      } else {
        mediator.trigger("page:show", self.currentPage.options.next.page);
      }
    });

    this.listenTo(mediator, "page:refresh", function () {
      self.currentPage.view.render();
    });

    this.listenTo(mediator, 'page:show', function (pageName, pageParams) {

      if (!pageParams) {
        pageParams = [];
      }

      var hash = '#' + pageName;

      if (pageParams.length > 0) {
        hash += '/' + pageParams.join('/');
      }

      window.location.hash = hash;
    });

  },

  createPageElement: function (pageName) {

    jQuery('#content')
        .prepend('<div style="display: none;" data-page="' + pageName + '"></div>');
    this.$contents[pageName] = jQuery('#content > [data-page="' + pageName + '"]');
  },

  createRoute: function (routePattern) {

    var self = this;
    (function (routePattern) {
      self.router.route(routePattern, self.routes[routePattern].name, function () {
        self.showPage(routePattern, arguments);
      });
    })(routePattern);
  },

  showPage: function (routePattern, args, callback) {

    if (routePattern === "") {

      return;
    }

    var self = this;

    if (!this.pages[routePattern]) {

      if (this.routes[routePattern]) {

        if (!this.controllers[this.routes[routePattern].controller]) {
          throw "Controller not found: " + this.routes[routePattern].controller;
        }

        // Route exists
        this.createPageElement(routePattern);
        this.pages[routePattern] = {
          $el: this.$contents[routePattern],
          controller: this.controllers[this.routes[routePattern].controller],
          options: this.routes[routePattern].data,
          route: routePattern
        };
        this.pages[routePattern].view = this.pages[routePattern].controller(
            this.$contents[routePattern],
            this.pages[routePattern].options
        );
        this.pages[routePattern].$el.append(this.pages[routePattern].view.$el);

        this.listenTo(self.pages[routePattern].view, 'afterRender', function () {
          mediator.trigger("page:show:" + routePattern, self.pages[routePattern]);
        });
      } else {

        // Route does not exist, custom page
        var pageData = mediator.alter('page', {
          name: self.pageName
        });
        self.routes[routePattern] = {
          controller: pageData.controller,
          data: pageData,
          name: pageData.name
        };
        self.showPage(routePattern, args, callback);

        return;
      }
    }

    // Check permissions
    if (this.routes[routePattern].permissions && this.routes[routePattern].permissions.length > 0) {
      var account = mediator.alter('account');
      var access = false;

      for (var i in this.routes[routePattern].permissions) {
        if (account.permissions.indexOf(this.routes[routePattern].permissions[i]) > -1) {
          access = true;
          break;
        }
      }

      if (access == false) {
        mediator.trigger("toast:warning", "You do not have access to that page.");

        window.history.back();

        return;
      }
    }

    // TODO: Work around for issue with not displaying first page
    if (!this.currentPage) {
      this.currentPage = this.pages[routePattern];
      this.pages[routePattern].view.render();
      this.$body
          .attr('data-page', routePattern)
          .attr('data-controller', this.routes[routePattern].controller);
      jQuery("body")
          .animate({
            scrollTop: 0
          }, 1);
      jQuery("#content")
          .animate({
            scrollTop: 0
          }, 1);
      jQuery('#content [data-page][data-page="' + routePattern + '"]')
          .fadeIn(500);

      return;
    } else {
      if (this.currentPage.view.suspend) {
        this.currentPage.view.suspend();
      }
    }

    this.currentPage = this.pages[routePattern];
    this.currentPage.view.options.args = args;

    // Start hiding other pages
    jQuery('#content [data-page][data-page!="' + routePattern + '"]')
        .fadeOut(500)
        .css('z-index', 100);

    // Start rendering new page
    this.currentPage.view.once('afterRender', function () {
      self.$body
          .attr('data-page', routePattern)
          .attr('data-controller', self.routes[routePattern].controller);
      mediator.trigger('page:change', routePattern);

      // Fade in new page after done rendering
      jQuery("body")
          .animate({
            scrollTop: 0
          }, 1);
      jQuery("#content")
          .animate({
            scrollTop: 0
          }, 1);
      jQuery('#content [data-page][data-page="' + routePattern + '"]')
          .fadeIn(500)
          .css('z-index', 1000);
    });

    // Hide or show pagination
    if (!this.currentPage.options.back ||
        this.currentPage.options.back.hidden === true) {
      jQuery('body')
          .attr('navigation-back', 'hidden');
    } else {
      jQuery('body')
          .attr('navigation-back', 'visible');
    }

    if (!this.currentPage.options.next ||
        this.currentPage.options.next.hidden === true) {
      jQuery('body')
          .attr('navigation-next', 'hidden');
    } else {
      jQuery('body')
          .attr('navigation-next', 'visible');
    }

    this.currentPage.view.render();

    if (this.currentPage.view.refresh) {
      this.currentPage.view.refresh();
    }

    this.$pageTitle.html(this.currentPage.options.title);
  },

  start: function () {
    var self = this;

    this.pages = {};
    this.router = new RouterClass();
    this.router.self = this;
    this.$content = jQuery('#content');
    this.$contents = {};
    this.$body = jQuery('body');
    this.$pageTitle = jQuery('#pageTop #pageTitle');

    // TODO: Added suspend/resume support to views
    this.currentPage = "";

    this.controllers = mediator.alter('controllers');
    this.routes = mediator.alter('routes');

    for (var i in this.routes) {
      this.createRoute(i);
    }

    // Add global listeners to pagination buttons
    jQuery('#pageTop')
        .on("click", "button[data-paginate=back]", function () {
          window.history.back();
        });

    jQuery('#pageTop')
        .on("click", "button[data-paginate=next]", function () {
          mediator.trigger('page:next');
        });

    jQuery('#pageBottom')
        .on("click", "button[data-paginate=next]", function () {
          mediator.trigger('page:next');
        });

    window.location.hash = "";
    Backbone.history.start();
  }
});
