var BaseView = require('./questionBase');
var mediator = require('./../mediator');

module.exports = BaseView.extend({

  afterRender: function () {
    var self = this;

    if (self.options.response &&
        self.options.response.status) {
      self.$el.find('[data-status="' + self.options.response.status + '"]')
          .show();
    }
  },

  beforeRender: function () {
    this.options.response = mediator.alter('response', {
      question: this.options.name
    });
  },

  initialize: function () {

    // TOOD: Dont bind to medaitor
    var self = this;
    mediator.on("response:set", function () {
      self.render();
    });
  },

  template: 'questionCalculated.mustache',

  validate: function () {

    return true;
  }

});