var BaseView = require('./questionBase');
var mediator = require('../mediator');

module.exports = BaseView.extend({
  afterRender: function () {

    if (this.options.response &&
        this.options.response.response) {
      this.options.response.response = parseInt(this.options.response.response);

      if (isNaN(this.options.response.response) === false) {
        var feet = Math.floor(this.options.response.response / 12);
        var inches = this.options.response.response - (feet * 12);
        this.$el.find('[name=feet]').val(feet);
        this.$el.find('[name=inches]').val(inches);
      }
    }
  },

  events: {

    'change select': function () {
      var feet = this.$el.find('[name=feet]').val();

      if (feet == '') {
        return;
      }

      var inches = this.$el.find('[name=inches]').val();

      if (inches == '') {
        return;
      }

      var height = parseInt(feet * 12) + parseInt(inches);
      this.setValue(height);
    }
  },

  template: 'questionHeight.mustache',

  val: function () {
    var feet = this.$el.find('[name=feet]').val();

    if (feet == '') {
      return false;
    }

    var inches = this.$el.find('[name=inches]').val();

    if (inches == '') {
      return false;
    }

    return true;
  }

});