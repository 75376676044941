var BaseView = require('./questionBase');

module.exports = BaseView.extend({
  afterRender: function () {
  },

  events: {
    "change textarea": function () {
      this.setValue(this.$el.find('textarea').val());
    }
  },

  template: 'questionTextArea.mustache',

  val: function () {
    return this.$el.find('textarea').val();
  }

});