var BaseView = require('./base');
var Moment = require('moment');

module.exports = BaseView.extend({
  afterRender: function () {
    /*
     if (this.options.type === "date") {
     if (this.value === undefined ||
     this.value === null ||
     this.value == "Invalid date") {
     if (this.options.default !== undefined) {
     this.val(Moment().add(this.options.default, 'days').format("YYYY-MM-DD"));
     }
     }
     this.$el.find('input').kendoDatePicker();
     this.$el.find('.k-select').html('<span class="fa fa-fw fa-sort-down"></span>');
     }
     if (this.value === undefined || this.value === null) {
     if (this.options.default !== undefined) {
     this.val(this.options.default);
     }
     } else {
     this.val(this.value);
     }*/
  },

  events: {
    change: function (e) {
      this.trigger('change', e);
    },

    keyup: function (e) {
      this.trigger('keyup', e);
    }
  },

  template: 'input.mustache',

  val: function (value) {

    if (value === undefined) {

      // Getting value
      if (this.options.type === "date") {
        this.value = Moment(this.$el.find('input').val()).format("YYYY-MM-DD");

        if (this.value === 'Invalid date') {
          this.value = "";
        }
      } else {
        this.value = this.$el.find('input').val();
      }
    } else {

      // Setting value
      if (this.options.type === "date") {
        this.value = Moment(value).format("YYYY-MM-DD");
      } else {
        this.value = value;
      }

      this.$el.find('input').val(this.value);
    }

    return this.value;
  }
});