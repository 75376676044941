var mediator = require('../mediator');
var LayoutManager = require('backbone.layoutmanager');
var jQuery = require('jquery');

var templateCache = {};

// Surpress warnings from using multiple top level elements.  TODO ## fix this
// https://github.com/tbranyen/backbone.layoutmanager/wiki/Configuration#warning
LayoutManager.configure({ suppressWarnings: true });

module.exports = LayoutManager.extend({
  el: false,
  fetchTemplate: function (path) {
    var self = this;

    if (templateCache[path]) {

      return templateCache[path];
    }

    // To put this method into async-mode, simply call `async` and store the
    // return value (callback function).
    var done = this.async();

    // Asynchronously fetch the path in `template` and compile the contents
    // into a template.
    (function (path, done) {
      jQuery.get("templates/" + path, function (contents) {
        templateCache[path] = mediator.template.compile(contents);
        done(templateCache[path]);
      }, "text");
    })(path, done);
  },

  renderTemplate: function (template, context) {
    if (!context) {
      context = {};
    }

    var output = template(context);

    return output;
  },

  serialize: function () {

    var returnData = {
      options: this.options
    };
    if(this.model) {
      returnData.model = this.model.attributes;
    }
    if(this.config) {
      returnData.config = this.config;
    }
    return returnData;
  },

  shake: function () {
    var self = this;
    this.$el.addClass('shake');
    setTimeout(function () {
      self.$el.removeClass('shake');
    }, 1000);
  }
});