var BaseView = require('./base');
var Backbone = require('backbone');
var mediator = require('../mediator');
var Moment = require('moment');

module.exports = BaseView.extend({

  afterRender: function () {

    var self = this;

    if (this.options.args &&
        this.options.args[0] &&
        this.model.has('screeningID') === false) {
      mediator.api("screening/get", {
        screeningID: this.options.args[0]
      }, function (err, data) {
        self.model.set(data);
      });
    }

    if (self.model.has('clientList') === false) {
      mediator.api("client/list", {}, function (err, clients) {
        self.model.set('clientList', clients);
      });
    }

    if (this.model.has('workflowList') === false) {
      mediator.api("workflow/list", {}, function (err, workflows) {
        self.model.set('workflowList', workflows);
      });
    }
  },

  eligibilityFileLoad: function () {
    var self = this;
    mediator.api("eligibilityFile/list", {
      clientID: this.model.get('clientClientID')
    }, function (err, list) {
      self.model.set('eligibilityFileList', list);
    });
  },

  events: {
    'click [data-cancel]': function () {
      window.history.back();
    },

    'click [data-save]': function () {
      this.save();
    },

    'change [name=clientClientID]': function (e) {
      this.model.set('clientClientID', jQuery(e.currentTarget).val());
    }
  },

  initialize: function () {

    var self = this;

    this.model = new Backbone.Model();

    this.model.on('change:uniqueID', function (model, value) {
      self.$el.find('[name=uniqueID]').val(value);
    });

    this.model.on('change:screeningID', function (model, value) {
      self.$el.find('[name=screeningID]').val(value);
    });

    this.model.on('change:name', function (model, value) {
      self.$el.find('[name=name]').val(value);
    });

    this.model.on('change:clientClientID', function (model, value) {
      self.$el.find('[name=clientClientID]').val(value);
      self.eligibilityFileLoad();
    });

    this.model.on('change:clientList', function (model, value) {
      var o = '<option value="">-- Select One -- </option>';
      for (var i in value) {
        o += '<option value="' + value[i].clientID + '">' + value[i].name + '</option>';
      }
      self.$el.find('[name=clientClientID]').html(o);
      self.$el.find('[name=clientClientID]').val(self.model.get('clientClientID'));
    });

    this.model.on('change:eligibilityFileEligibilityFileID', function (model, value) {
      self.$el.find('[name=eligibilityFileEligibilityFileID]').val(self.model.get('eligibilityFileEligibilityFileID'));
    });

    this.model.on('change:eligibilityFileList', function (model, value) {
      var o = '<option value="">-- Select One -- </option>';
      for (var i in value) {
        o += '<option value="' + value[i].eligibilityFileID + '">' + value[i].name + '</option>';
      }
      self.$el.find('[name=eligibilityFileEligibilityFileID]').html(o);
      self.$el.find('[name=eligibilityFileEligibilityFileID]').val(self.model.get('eligibilityFileEligibilityFileID'));
    });

    this.model.on('change:workflowName', function (model, value) {
      self.$el.find('[name=workflowName]').val(value);
    });

    this.model.on('change:workflowList', function (model, value) {
      var o = '<option value="">-- Select One -- </option>';
      for (var i in value) {
        o += '<option value="' + value[i].name + '">' + value[i].title + '</option>';
      }
      self.$el.find('[name=workflowName]').html(o);
      if (self.options.workflow) {
        self.$el.find('[name=workflowName]').val(self.options.workflow);
      }
      self.$el.find('[name=workflowName]').val(self.model.get('workflowName'));
    });

    this.model.on('change:startTime', function (model, value) {
      self.$el.find('[name=startTime]').val(Moment(value).format('YYYY-MM-DD'));
    });

    this.model.on('change:endTime', function (model, value) {
      self.$el.find('[name=endTime]').val(Moment(value).format('YYYY-MM-DD'));
    });
  },

  refresh: function () {
    this.model.clear();
  },

  save: function () {
    var self = this;
    var vals = this.$el.find('form').serializeArray();
    var data = {};
    for (var i in vals) {
      data[vals[i].name] = vals[i].value;
    }

    // validations
    var errors = false;
    if (!data.name || data.name === "") {
      errors = true;
      mediator.trigger('toast:warning', "Name is required");
    }
    if (!data.startTime || data.startTime === "") {
      errors = true;
      mediator.trigger('toast:warning', "Start date is required");
    }
    if (!data.endTime || data.endTime === "") {
      errors = true;
      mediator.trigger('toast:warning', "End date is required");
    }
    if (!data.workflowName || data.workflowName === "") {
      errors = true;
      mediator.trigger('toast:warning', "Workflow is required");
    }
    if (!data.clientClientID || data.clientClientID === "") {
      errors = true;
      mediator.trigger('toast:warning', "Client is required");
    }
    if (errors === false) {
      if (data.uniqueID === '') {
        data.uniqueID = mediator.guid();
      }

      mediator.api("screening/update", data, function (err, data) {
        if (err) {
          mediator.trigger("toast:warning", '{{ t "' + err + '" }}');
          return;
        }

        var screening = mediator.alter('screening');
        if (data.screeningID === screening.screeningID) {
          mediator.trigger("screening:set", data);
        }
        mediator.trigger("toast:success", "Screening saved successfully.");
        window.history.back();
      });
    }
  },

  template: 'dashboardScreeningEdit.mustache'
});