var Backbone = require('backbone');

module.exports = Backbone.Model.extend({

  alter: {
    version: function (data) {
      for (var i in this.attributes) {
        data[i] = this.attributes[i];
      }
    }
  }

});