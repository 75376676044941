var BaseView = require('./base');

module.exports = BaseView.extend({
  afterRender: function () {
  },

  events: {},

  initialize: function () {
    this.setView('.header', this.options.header);
    this.removeView('.items');

    for (var i in this.options.items) {
      this.insertView('.items', this.options.items[i]);
    }
  },

  template: 'resultGroup.mustache'
});