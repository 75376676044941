var BaseView = require('./base');
var mediator = require('../mediator');

module.exports = BaseView.extend({
  afterRender: function() {

    if (!this.options.value && this.options.value !== 0) {
      this.$el.hide();
    }
  },

  beforeRender: function() {
    var participant = mediator.alter('participant');
    this.options.value = participant[this.options.participant];
  },

  events: {},

  template: 'participantValue.mustache'
});