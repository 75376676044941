var BaseView = require('./base');
var mediator = require('../mediator');

module.exports = BaseView.extend({
  afterRender: function () {
    if (this.options.screening.active === false) {
      this.$el.find('.active')
          .hide();
    } else {
      this.$el.find('.noActive')
          .hide();
    }
  },

  beforeRender: function () {
    this.options.screening = mediator.alter('screening');
  },

  events: {
    "click button": function () {
      mediator.api("report/generate", {}, function (err, returnData) {
        if (err) {
          mediator.trigger("toast:warning", '{{ t "' + err + '" }}');

          return;
        }

        mediator.trigger("toast:success", 'Starting report generation...');
      });
    }
  },

  template: 'dashboardMiniReport.mustache'
});