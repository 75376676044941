var Backbone = require('backbone');
var mediator = require('../mediator');
var toastr = require('toastr');

var cache = {};

module.exports = Backbone.Model.extend({
  attachEvents: function () {
    var self = this;

    this.listenTo(mediator, "toast:info", function (text) {
      self.info(text);
    });

    this.listenTo(mediator, "toast:error", function (text) {
      self.error(text);
    });

    this.listenTo(mediator, "toast:success", function (text) {
      self.success(text);
    });

    this.listenTo(mediator, "toast:warning", function (text) {
      self.warning(text);
    });
  },

  info: function (text) {
    if (cache[text]) {
      return;
    }
    toastr.info(mediator.template.text(text));
    cache[text] = setTimeout(function () {
      delete(cache[text]);
    }, 6000);
  },

  error: function (text) {
    if (cache[text]) {
      return;
    }
    toastr.error(mediator.template.text(text), "ERROR", {
      timeOut: 0
    });
    cache[text] = setTimeout(function () {
      delete(cache[text]);
    }, 6000);
  },

  success: function (text) {
    if (cache[text]) {
      return;
    }
    toastr.success(mediator.template.text(text));
    cache[text] = setTimeout(function () {
      delete(cache[text]);
    }, 6000);
  },

  warning: function (text) {
    if (cache[text]) {
      return;
    }
    toastr.warning(mediator.template.text(text));
    cache[text] = setTimeout(function () {
      delete(cache[text]);
    }, 6000);
  }

});