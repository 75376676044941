var Backbone = require('backbone');
var mediator = require('../mediator');

module.exports = Backbone.Model.extend({

  alter: {
    controllers: function (controllers) {
      for (var i in this.controllers) {
        controllers[i] = this.controllers[i];
      }
    },

    dashboard: function (data) {
      data.reports = {
        fa: 'fa-bar-chart',
        page: 'dashboard/reports',
        text: 'Reports',
        view: 'dashboardMiniReport',
        weight: 300
      };
    },

    routes: function (routes) {
      routes['dashboard/reports'] = {
        controller: 'dashboardReport',
        data: {
          title: "Reports"
        },
        name: "dashboard/reports"
      };
    }
  },

  attachEvents: function () {

    var self = this;

    this.listenTo(mediator, "file:download", function (fileid) {
      window.location.href = "/file/get/" + fileid;
    });
  },

  controllers: {
    dashboardReport: function ($el, data) {
      var PageView = require('../views/dashboardReport');
      var view = new PageView(data);

      return view;
    }
  }
});