var BaseView = require('./base');
var ResultGroupView = require('./resultGroup');
var ParticipantView = require('./participantValue');
var QuestionView = require('./question');
var ResultView = require('./result');
var HeaderView = require('./header');
var mediator = require('../mediator');
var Moment = require('moment');

module.exports = BaseView.extend({

  afterRender: function () {
    for (var i in this.config.print) {
      this.updatePrint(this.config.print[i].printform);
    }

  },

  beforeRender: function () {
    this.removeView('.groups');
    var header = null;
    var group;

    this.questions = [];

    for (var i in this.options.config.questions) {

      if (this.options.config.questions[i].header) {

        // Header
        if (header) {

          // Finish group
          this.insertView('.groups', new ResultGroupView({
            header: header,
            items: group
          }));
        }

        header = new HeaderView(this.options.config.questions[i]);
        group = [];
      } else if (this.options.config.questions[i].questionActive) {

        // Answerable question
        var activeQuestion = new QuestionView({
          question: this.options.config.questions[i].questionActive
        });
        group.push(activeQuestion);
        this.questions.push(activeQuestion);
      } else if (this.options.config.questions[i].question) {

        // Display response to question
        group.push(new ResultView(this.options.config.questions[i]));
      } else if (this.options.config.questions[i].participant) {

        // Display participant value
        group.push(new ParticipantView(this.options.config.questions[i]));
      }
    }

    if (header) {

      // Finish group
      this.insertView('.groups', new ResultGroupView({
        header: header,
        items: group
      }));
    }
  },

  initialize: function () {

  },

  events: {
    'click .print button': function (e) {
      var printform = jQuery(e.currentTarget)
        .attr('data-form');
      this.updatePrint(printform, function () {
        window.print();
      });
    }
  },

  updatePrint: function (form, callback) {
    var self = this;
    jQuery.get('printForms/' + form + '.mustache', function (contents) {
      contents = mediator.template.compile(contents);
      var data = {};
      data.participant = mediator.alter('participant');
      data.response = mediator.alter('responses');
      data.date = Moment()
        .format('MM/DD/YYYY');
      data.questions = mediator.alter('questions');
      jQuery('#printForm')
        .html(contents(data));

      if (callback) {
        callback();
      }
    }, 'text');
  },

  template: 'resultsReviewPage.mustache',

  validate: function () {
    var validate = true;

    for (var i in this.questions) {

      if (this.questions[i].validate) {

        if (this.questions[i].validate() === false) {
          validate = false;
        }
      }
    }

    if (validate === true) {
      if (this.config.closeout === false) {
        return;
      }

      var participant = mediator.alter('participant');
      mediator.api('participant/status', {
        participantID: participant.participantID,
        status: 'closeout'
      }, function (err, data) {
        mediator.trigger('participant:status', data);
      });
    }

    return validate;
  }
});