var BaseView = require('./base');
var jQuery = require('jquery');

module.exports = BaseView.extend({

  initialize: function () {

    var self = this;

    jQuery.ajax("templates/" + this.options.config.templateFile, {
      error: function () {
        alert("Error loading template.");
      },

      success: function (data) {
        self.options.markup = data;
        self.render();
      }
    });
  },

  template: 'templatePage.mustache'
});