var BaseView = require('./base');
var mediator = require('../mediator');
var DashboardCurrentItemView = require('./dashboardCurrentItem');

module.exports = BaseView.extend({

  afterRender: function () {

    var self = this;

    if(this.options.screening.active === false) {
      // Make API call to grab screening list
      this.items = [];
      mediator.api("screening/active", {}, function(err, returnData) {

        if(err) {

          return;
        }

        var view;
        self.$el.find('.items').empty();

        for(var i in returnData) {
          view = new DashboardCurrentItemView(returnData[i]);
          self.items.push(view);
          self.$el.find('.items').append(view.$el);
          view.render();
        }
      });
    }
  },

  beforeRender: function () {
    this.options.screening = mediator.alter('screening');
  },

  events: {
    "click button.conclude": function() {
      mediator.trigger("modal:confirm", {
        text: 'Are you sure you want to conclude this screening? Concluding a screening will log all users out of the system.',
        title: 'Screening conclude confirmation',
        buttons: [
          'Conclude',
          'Cancel'
        ]
      }, function (err, buttonText) {
        if(buttonText === 'Conclude') {
          mediator.api("screening/conclude", {});
        }
      });

    }
  },

  template: 'dashboardCurrent.mustache'
});