var Backbone = require('backbone');
var jQuery = require('jquery');
var mediator = require('../mediator');

// TODO: Find way to automate this
var views = {
  dashboardCurrent: require('../views/dashboardCurrent')
};

module.exports = Backbone.Model.extend({

  alter: {
    controllers: function (controllers) {

      for (var i in this.controllers) {
        controllers[i] = this.controllers[i];
      }
    },

    dashboard: function (data) {

      data.internet = {
        fa: 'fa-wifi',
        page: 'dashboard/internet',
        text: 'Internet',
        view: 'dashboardMiniInternet',
        weight: 500
      };

      data.status = {
        fa: 'fa-heart',
        page: 'dashboard/status',
        text: 'Server Status',
        weight: 1000
      };
    },

    routes: function (routes) {
      routes.dashboard = {
        controller: 'dashboard',
        data: {
          title: 'Dashboard'
        },
        permissions: [
          'dashboard'
        ],
        name: 'dashboard'
      };

      routes['dashboard/internet'] = {
        controller: 'dashboardInternet',
        data: {
          title: 'Internet Status'
        },
        name: 'dashboardInternet'
      };

      routes['dashboard/status'] = {
        controller: 'dashboardStatus',
        data: {
          title: 'Server Status'
        },
        name: 'dashboardStatus'
      };
    }
  },

  controllers: {
    dashboard: function ($el, data) {
      var PageView = require('../views/dashboard');
      var view = new PageView(data);

      return view;
    },

    dashboardInternet: function ($el, data) {
      var PageView = require('../views/dashboardInternet');
      var view = new PageView(data);

      return view;
    },

    dashboardStatus: function ($el, data) {
      var PageView = require('../views/dashboardStatus');
      var view = new PageView(data);

      return view;
    }
  }
});