var BaseView = require('./base');
var mediator = require('../mediator');

module.exports = BaseView.extend({
  afterRender: function () {

  },

  beforeRender: function () {
    if (!this.timeout) {
      this.update();
    }
  },

  events: {},

  template: 'dashboardMiniInternet.mustache',

  update: function () {

  }
});