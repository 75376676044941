var BaseView = require('./base');
var jQuery = require('jquery');
var mediator = require('../mediator');

module.exports = BaseView.extend({

  beforeRender: function() {
    if(this.options.status === 'canceled') {
      this.options.canceled = true;
    } else {
      this.options.canceled = false;
    }
  },

  events: {
    "click [data-cancel]": function() {
      var self = this;
      mediator.api("job/cancel", {
        jobID: this.options.jobID
      }, function(err, returnData) {
        self.options = returnData;
        self.render();
      });
    },

    "click [data-resume]": function() {
      var self = this;
      mediator.api("job/resume", {
        jobID: this.options.jobID
      }, function(err, returnData) {
        self.options = returnData;
        self.render();
      });
    },

    "click [data-close]": function() {
      mediator.trigger("modal:close");
    }
  },

  initialize: function() {
    var self = this;
    this.timeout = setInterval(function() {
      self.update();
    }, 2000);
  },

  suspend: function() {
    clearInterval(this.timeout);
  },

  template: 'dashboardJobDetail.mustache',

  update: function() {
    var self = this;
    mediator.api("job/get", {
      jobID: this.options.jobID
    }, function(err, returnData) {
      self.options = returnData;
      self.render();

      /*
      self.$el.find('[data-cancel]').hide();
      self.$el.find('[data-resume]').hide();

      switch(self.options.status) {
        case 'disconnected':
          break;

        case 'offline':
          self.$el.find('[data-online]').show();
          break;

        case 'idle':
          self.$el.find('[data-offline]').show();
          break;
      }*/
    });
  }
});