var Backbone = require('backbone');
var mediator = require('../mediator');

module.exports = Backbone.Model.extend({

  alter: {
    controllers: function (controllers) {
      for (var i in this.controllers) {
        controllers[i] = this.controllers[i];
      }
    },

    dashboard: function (data) {
      data.jobs = {
        fa: 'fa-briefcase',
        page: 'dashboard/jobs',
        text: 'Jobs',
        weight: 500
      };
    },

    routes: function (routes) {
      routes['dashboard/jobs'] = {
        controller: 'dashboardJobs',
        data: {
          title: "Jobs"
        },
        name: "dashboardJobs"
      };
    }
  },

  controllers: {
    dashboardJobs: function ($el, data) {
      var PageView = require('../views/dashboardJobs');
      var view = new PageView(data);

      return view;
    }
  }
});