var BaseView = require('./base');
var mediator = require('../mediator');

module.exports = BaseView.extend({

  events: {
    "click .edit": function () {
      window.location.hash = "#dashboard/eligibilityFileEdit/" + this.options.eligibilityFileID;
    },

    "click .delete": function () {

      var self = this;

      // TODO: Possibly detect if deleting file from current screening

      mediator.trigger("modal:confirm", {
        text: 'Are you sure you want to delete this eligibility file?',
        title: 'Confirm eligibility file deletion',
        buttons: [
          'Delete',
          'Cancel'
        ]
      }, function (err, buttonText) {

        if (buttonText === "Delete") {
          mediator.api("eligibilityFile/delete", {
            eligibilityFileID: self.options.eligibilityFileID
          }, function (err) {

            if (err) {
              mediator.trigger("toast:warning", '{{ t "' + err + '" }}');

              return;
            }

            mediator.trigger("page:refresh");
          });
        }
      });
    }
  },

  initialize: function () {
    this.options.errors = JSON.parse(this.options.errors);
  },

  template: 'dashboardEligibilityFilesItem.mustache'
});