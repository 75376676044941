var BaseView = require('./base');
var jQuery = require('jquery');

module.exports = BaseView.extend({

  events: {
    "click button": function(e) {
      var text = jQuery(e.currentTarget).attr('data-text');
      this.trigger("callback", text);
      this.trigger("close");
    }
  },

  template: 'confirm.mustache'
});