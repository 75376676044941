var BaseView = require('./base');
var Moment = require('moment');
var mediator = require('../mediator');

module.exports = BaseView.extend({
  afterRender: function () {
    if (this.condition) {
      // Check condition on this range before adding it
      var result = mediator.alter('condition', {
        conditions: this.condition
      });

      if (result.success === true) {
        this.$el.show();
      } else {
        this.$el.hide();
      }
    }
    if (this.options.extra) {
      this.loadExtra();
    }
  },

  loadExtra: function () {
    // Load extra template
    var self = this;
    var template = this.fetchTemplate(this.options.extra);
    if (template) {
      var output = this.renderTemplate(template, {});
      this.$el.find('.extraTemplate')
        .html(output);
    } else {
      setTimeout(function () {
        self.loadExtra();
      }, 500);
    }
  },

  events: {
    click: function () {
      var self = this;

      if (self.options.extra) {
        self.$el.find('.extraTemplate')
          .toggle('slow');
      }
    }
  },

  template: 'header.mustache'
});