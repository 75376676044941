var BaseView = require('./base');
var mediator = require('../mediator');

module.exports = BaseView.extend({

  events: {
    click: function() {
      mediator.api("screening/activate", {
        screeningID: this.options.screeningID
      }, function(err) {

        if(err) {
          mediator.trigger("toast:warning", '{{ t "' + err + '" }}');
        }
      });
    }
  },

  template: 'dashboardCurrentItem.mustache'
});