var BaseView = require('./questionBase');
var jQuery = require('jquery');

module.exports = BaseView.extend({

  afterRender: function () {
    if (this.options.response && this.options.response.response) {
      var options = this.options.response.response.split(";");
      for (var i in options) {
        this.$el.find('input[value=' + options[i] + "]")
            .prop('checked', true);
        ;
      }
    }
  },

  beforeRender: function () {
    if (this.options.question.optionColumns === undefined ||
        this.options.question.optionColumns === null) {
      this.options.question.optionColumns = 3;
    }
    if (this.options.question.optionColumnsMedium === undefined ||
        this.options.question.optionColumnsMedium === null) {
      this.options.question.optionColumnsMedium = 6;
    }
  },

  events: {
    "click input": function (e) {
      this.setValue(this.val());
    }
  },

  template: 'questionCheckbox.mustache',

  val: function () {
    var values = [];
    var checked = this.$el.find('input:checked')
        .each(function (index, item) {
          values.push(jQuery(item)
              .attr('value'));
        });
    return values.join(";");
  }
});
