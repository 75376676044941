var BaseView = require('./base');
var mediator = require('../mediator');
var DashboardJobsItemView = require('./dashboardJobsItem');

module.exports = BaseView.extend({

  afterRender: function () {

    this.update();
  },

  attachEvents: function() {
    alert('attach');
  },

  beforeRender: function () {
    this.options.screening = mediator.alter('screening');
  },

  events: {
  },

  initialize: function() {
    var self = this;

    this.listenTo(mediator, "job:add", function() {
      self.update();
    });

    this.listenTo(mediator, "job:update", function() {
      self.update();
    });
  },

  suspend: function() {
    clearTimeout(this.timeout);
  },

  template: 'dashboardJobs.mustache',

  update: function() {

    clearTimeout(this.timeout);
    var self = this;
    mediator.api("job/list", {}, function(err, returnData) {

      self.timeout = setTimeout(function() {
        self.update();
      }, 10000);

      if(err) {

        return;
      }

      var view;
      self.$el.find('.items').empty();

      for(var i in returnData) {
        view = new DashboardJobsItemView(returnData[i]);
        self.$el.find('.items').append(view.$el);
        view.render();
      }
    });
  }
});