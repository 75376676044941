var BaseView = require('./questionBase');
var jQuery = require('jquery');

module.exports = BaseView.extend({

  afterRender: function () {

    if (this.options.response) {
      this.$el.find('select').val(this.options.response.response);
    }
  },

  events: {
    "change select": function (e) {
      var val = jQuery(e.currentTarget).val();
      this.setValue(val);
    }
  },

  template: 'questionSelect.mustache',

  val: function () {
    return this.$el.find('select').val();
  }
});