/**
 * Can pass 'barGraph = false' in as option to hide bar graphs
 *
 * @type {*|exports|module.exports}
 */
var BaseView = require('./question');
var mediator = require('../mediator');

module.exports = BaseView.extend({

  afterRender: function () {
    var self = this;

    if (this.options.response &&
      this.options.response.status) {
      this.$el.find('[data-status="' + this.options.response.status + '"]')
        .show();
    }

    if (this.options.question.condition) {
      var result = mediator.alter('condition', {
        conditions: this.options.question.condition
      });

      if (result.success === true) {
        this.$el.clearQueue()
          .show();
      } else {
        this.$el.clearQueue()
          .hide();
        // Band-aid for first render which does not hide correctly
        setTimeout(function () {
          self.$el.hide();
        }, 500);
      }
    }
  },

  attachEvents: function () {
    var self = this;
    this.listenTo(mediator, 'resize', function () {
      self.$el.find('.bar svg g:first')
        .find('path:first')
        .attr('fill', '#d9d9d9');
    });
  },

  beforeRender: function () {

    if (this.options.barGraph === null || this.options.barGraph === undefined) {
      this.options.barGraph = true;
    }

    if (this.options.question.questionType === 'multiple') {

      for (var i in this.options.question.options) {

        if (this.options.response.response === this.options.question.options[i].value) {
          this.options.responseText = this.options.question.options[i].text;
          break;
        }
      }
    } else {

      this.options.responseText = this.options.response.response;

      // If this is matching a question option (e.g. OOR condition), use the text provided instead of the response value
      for (var i in this.options.question.options) {

        if (this.options.response.response === this.options.question.options[i].value) {
          this.options.responseText = this.options.question.options[i].text;
          break;
        }
      }
    }

    // Alter response using a template
    if (this.options.valueTemplate) {
      this.options.responseText = mediator.template.text(this.options.valueTemplate, this.options);
    }
  },

  events: {},

  initialize: function () {
    var self = this;

    this.options.name = this.options.question;
    this.listenTo(mediator, 'response:change:' + this.options.name, function (data) {
      self.options.response = data;
      self.render();
    });

    this.loadQuestion(function () {
      self.loadResponse(function () {
        self.render();
      });
    });
  },

  template: 'result.mustache'
});
