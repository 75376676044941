var Backbone = require('backbone');
var mediator = require('../mediator');
var i18n = require('i18next');
var FSBackend = require('i18next-node-fs-backend');
var XHRBackend = require('i18next-xhr-backend');

module.exports = Backbone.Model.extend({

  defaults: {
    backend: {
      loadPath: '/locales/{{lng}}.json'
    },
    debug: true,
    fallbackLng: 'en',
    lng: 'en',
    preload: ['en', 'es']
  },

  start: function (callback) {
    var self = this;

    this.listenTo(mediator, "template:helpers", function (Handlebars) {
      self.registerHelpers(Handlebars);
    });

    // Use custom logger to pass i18n logging to our logging system
    i18n.use({
      type: 'logger',

      log: function (args) {
        mediator.log(args[0]);
      },

      warn: function (args) {
        mediator.log(args[0]);
      },

      error: function (args) {
        mediator.error(args[0]);
      }
    });

    if (typeof window == "undefined") {
      i18n.use(FSBackend);

      // Set file system specific options
      this.set('backend', {
        loadPath: __dirname + '/../../../locales/{{lng}}.json'
      });
    } else {
      i18n.use(XHRBackend);
    }

    i18n.init(this.attributes, function (err, t) {

      if (typeof err === "object") {
        if (callback) {
          callback(err);
        }

        return;
      }

      if (typeof t === "function") {
        self.t = t;
      }

      mediator.trigger("i18n:ready");
      if (callback) {
        callback(null);
      }
    });

  },

  /**
   * Allow attaching of functions to the mediator to make them 'global'
   * @param mediator
   */
  mediatorAlter: function (mediator) {
    mediator.translate = this.translate;
  },

  /**
   * Create new 'helpers' Handlebars can use in its templating.
   *
   * @param Handlebars
   */
  registerHelpers: function (Handlebars) {
    var self = this;

    if(!self.t) {
      console.log('Waiting on t...');
      setTimeout(function() {
        self.registerHelpers(Handlebars);
      }, 100);
      return;
    }

    Handlebars.registerHelper('t', function (i18nKey) {
      if(!self.t) {
        //DEBUG - Somehow race condition causes loading out of order
        alert('self.t not existing');
        window.location.reload();
      }
      var result = self.t(i18nKey);

      return new Handlebars.SafeString(result);
    });

  },

  translate: function (text, lang) {
    var result;

    if (lang) {
      result = this.t(text, {
        lng: lang
      });
    } else {
      result = this.t(text);
    }

    return result;
  }
});