/**
 * @augments Backbone.Model
 * @name Connectors.HTTP.App
 * @class
 */

var Backbone = require('backbone');
var mediator = require('../../mediator');
var jQuery = require('jquery');

module.exports = Backbone.Model.extend({
  attachEvents: function() {

  },

  api: function(api, data, callback) {
    var self = this;

    var account = mediator.alter('account');

    var url = window.location.protocol + "//" + window.location.host + "/api/v" + this.get('version') + "/" + api;
    data.token = account.token;
    if (mediator.bootToken) {
      data.bootToken = mediator.bootToken;
    }
    if (mediator.instance) {
      data.instance = mediator.instance;
    }
    mediator.ajax(url, data, function(err, data) {

      if (!data) {
        data = {};
      }

      if (!data.returnData) {
        data.returnData = {};
      }

      // Error from the API
      if (data && data.err) {
        callback(data.err);

        return;
      }

      // Error from the ajax request itself
      if (err) {
        callback(err);

        return;
      }

      if (data && data.events && data.events.length > 0) {

        var items;
        for (var i in data.events) {
          items = data.events[i].data;
          if (!items) {
            items = [];
          }
          items.unshift(data.events[i].event);
          mediator.trigger.apply(mediator, items);
          //mediator.trigger(data.events[i].event, data.events[i].data);
        }
      }

      if (callback) {
        callback(null, data.returnData);
      }
    });
  },

  connect: function() {

  },

  defaults: {
    version: '1'
  },

  initialize: function() {

  },

  mediatorAlter: function() {
    var self = this;
    mediator.api = function() {
      self.api.apply(self, arguments);
    };
  },

  start: function() {

    var self = this;

    // Verify that we can talk to the server
    mediator.log('Client.Connectors.HTTP.start');
    this.api('ping', {}, function(err, returnData) {

      if (err) {
        mediator.error("Could not ping server.");

        return;
      }

      mediator.log("Pingged server.");
    });
  }
});