var BaseView = require('./base');
var Backbone = require('backbone');
var mediator = require('../mediator');

module.exports = BaseView.extend({

  afterRender: function () {

    var self = this;

    if (this.options.args &&
        this.options.args[0] &&
        this.model.has('eligibilityFileID') === false) {
      mediator.api("eligibilityFile/get", {
        eligibilityFileID: this.options.args[0]
      }, function (err, data) {
        self.model.set(data);
      });
    }

    if (self.model.has('clientList') === false) {
      mediator.api("client/list", {}, function (err, clients) {
        self.model.set('clientList', clients);
      });
    }

    if (this.model.has('workflowList') === false) {
      mediator.api("workflow/list", {}, function (err, workflows) {
        self.model.set('workflowList', workflows);
      });
    }

    this.$messages = this.$el.find('[data-messages]');
    this.$status = this.$el.find('.data-status');
    this.$progress = this.$el.find('.progress .fill');
  },

  events: {
    'click [data-cancel]': function () {
      window.history.back();
    },

    'click [data-save]': function () {
      this.save();
    }
  },

  initialize: function () {

    var self = this;

    this.completed = false;

    this.listenTo(mediator, "eligibilityFile:error", function (data) {
      self.$messages.append("<div>Row " + data.row + " has the following errors: " + data.errors.join(", ") + "</div>");
    });

    this.listenTo(mediator, "eligibilityFile:progress", function (data) {
      self.$progress.width(data + "%");
    });

    this.listenTo(mediator, "eligibilityFile:complete", function (data) {
      if (this.completed === false) {
        this.completed = true;
        mediator.trigger("toast:success", "Eligibility file uploaded successfully.");
      }
    });

    this.model = new Backbone.Model();

    this.model.on('change:eligibilityFileID', function (model, value) {
      self.$el.find('[name=eligibilityFileID]').val(value);
    });

    this.model.on('change:name', function (model, value) {
      self.$el.find('[name=name]').val(value);
    });

    this.model.on('change:clientClientID', function (model, value) {
      self.$el.find('[name=clientClientID]').val(value);
    });

    this.model.on('change:clientList', function (model, value) {
      var o = '<option value="">-- Select One -- </option>';
      for (var i in value) {
        o += '<option value="' + value[i].clientID + '">' + value[i].name + '</option>';
      }
      self.$el.find('[name=clientClientID]').html(o);
      self.$el.find('[name=clientClientID]').val(self.model.get('clientClientID'));
    });
  },

  refresh: function () {
    this.model.clear();
  },

  save: function () {

    var self = this;
    var vals = this.$el.find('form').serializeArray();
    var data = {};
    for (var i in vals) {
      data[vals[i].name] = vals[i].value;
    }

    // validations
    var errors = false;
    if (!data.name || data.name === "") {
      errors = true;
      mediator.trigger('toast:warning', "Name is required");
    }
    if (!data.clientClientID || data.clientClientID === "") {
      errors = true;
      mediator.trigger('toast:warning', "Client is required");
    }

    // Validate file
    var input = this.$el.find('input[type=file]')[0];
    if (input.files.length > 0 &&
        (input.files[0].type !== '' &&
        input.files[0].type !== "text/csv" &&
        input.files[0].type !== "text/comma-separated-values" &&
        input.files[0].type !== "application/vnd.ms-excel")) {
      errors = true;
      mediator.trigger('toast:warning', "Eligibility file must be a CSV file.");
    }

    if (errors === false) {
      this.$messages.html('');
      this.completed = false;

      if (input.files.length > 0 &&
          input.files[0].size > 1000000) {
        mediator.trigger('toast:info', 'This file is large, please be patient.');
      }

      if (data.uniqueID === '') {
        data.uniqueID = mediator.guid();
      }

      if (input.files.length === 0) {
        // Did not attach file
        mediator.api("eligibilityFile/update", data, function (err, data) {
          if (err) {
            mediator.trigger("toast:warning", '{{ t "' + err + '" }}');
            return;
          }

          mediator.trigger("toast:success", "Eligibilty file saved successfully without updating the file.");
          window.history.back();
        });
      } else {
        // did attach file
        // Send file
        this.$el.find('button').hide();
        var reader = new window.FileReader();
        reader.onabort = function () {
        };
        reader.onerror = function () {
          mediator.trigger('toast:error', 'There was a problem with reading the file. Please try again.');
        };
        reader.onprogress = function (event) {
        };
        reader.onload = function (theFile) {
        };
        reader.onloadend = function (e) {
          self.status('Sending file...');
          self.$el.find('.progress .fill').show();
          self.$el.find('.progress .fill').width('0%');
          data.data = e.target.result;

          // Did attach file
          mediator.api("eligibilityFile/update", data, function (err, data) {
            if (err) {
              mediator.trigger("toast:warning", '{{ t "' + err + '" }}');
              return;
            }

            mediator.trigger("toast:success", "Eligibility file uploaded and is currently processing.");
          });
        };
        reader.onloadstart = function () {
          self.status('Loading file...');
        };
        reader.readAsText(input.files[0]);
      }
    }
  },

  status: function (text) {
    mediator.trigger("toast:info", text);
  },

  template: 'dashboardEligibilityFileEdit.mustache'
});

/*


 Network.send('eligibilityfile:upload', data, function (data) {
 self.status('Adding participants...');
 self.$el.find('.progress .fill').width(data.progress + '%');
 if (data.errors) {
 var markup;
 for (var i in data.errors) {
 markup = '<div class="ui-state-error ui-corner-all" style="padding: 0 .7em;">';
 markup += '<p><span class="ui-icon ui-icon-alert" style="float: left; margin-right: .3em;"></span>';
 markup += data.errors[i];
 markup += '</p>';
 markup += '</div>';
 self.$el.find('[data-messages]').append(markup);
 }
 }
 if (data.progress === 100) {
 self.$el.find('button').hide();
 self.$el.find('button[data-back]').show();
 self.status('Complete.');
 Backbone.trigger('toast:success', 'Eligibility file upload finished.');
 }
 });
 */