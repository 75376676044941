var Backbone = require('backbone');
var jQuery = require('jquery');
window.jQuery = jQuery;
var foundation = require('foundation-sites').default;

foundation.addToJquery(jQuery);
var MediatorClass = Backbone.Model.extend({

  ajax: function(url, data, callback) {
    jQuery
        .ajax(url, {
          data: data,
          method: "POST"
        })
        .done(function(data) {
          callback(null, data);
        })
        .fail(function(jqXHR, textStatus, errorThrown) {
          mediator.error(errorThrown);
          callback(textStatus);
        });
  },

  alter: function(event, data) {

    if (!data) {
      data = {};
    }

    for (var i in this.modules) {

      if (this.modules[i].alter && this.modules[i].alter[event]) {
        this.modules[i].alter[event].call(this.modules[i], data);
      }
    }

    return data;
  },

  defaults: {
    logging: "console"
  },

  guid: function() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
      var r = Math.random() * 16 | 0;
      var v = c == 'x' ? r : (r & 0x3 | 0x8);

      return v.toString(16);
    });
  },

  initialize: function() {

    var self = this;
    this.modules = {};

    // TODO: Move
    this.on('menu:toggle', function() {

    });
  },

  log: function() {
    console.log(arguments);
  },

  loadConfig: function() {
    var self = this;
    return new Promise(function(resolve, reject) {
      self.ajax('api/v1/config', {}, function(err, config) {

        if (err) {
          alert("Error loading configuration");

          return;
        }

        self.config = config.returnData;
        self.set(config.app);
        resolve();
      });
    });
  },

  loadModule: function(name, Module, options) {

    if (!options) {
      options = {};
    }

    this.modules[name] = new Module(options);

    if (this.modules[name].mediatorAlter) {
      this.modules[name].mediatorAlter(this);
    }

    if (this.modules[name].attachEvents) {
      this.modules[name].attachEvents(this);
    }
  },

  start: function() {
    var self = this;

    // DEBUG - Testing print forms
    //var PrintForm = require('./views/printForms/insurance1500');
    //this.printForm = new PrintForm();
    //jQuery("#printForm").empty().append(this.printForm.$el);
    //this.printForm.render();
    //window.test = function () {
    //self.trigger('print:insurance1500');
    //};

    this.once("template:ready", function() {
      self.trigger('page:show', 'login');
      jQuery('#wrapper')
          .css('display', 'block');
      jQuery('#loading')
          .remove();
    });

    this.body = jQuery("body");
    this.menuIcon = jQuery('#menuIcon');
    this.wrapper = jQuery('#wrapper');
    // Load main menu
    var MainMenuView = require('./views/mainMenu');
    this.mainMenu = new MainMenuView();
    jQuery("body")
        .append(this.mainMenu.$el);
    this.mainMenu.render();

    for (var i in self.modules) {

      if (self.config[i]) {
        self.modules[i].set(self.config[i]);
      }

      if (self.modules[i].start) {
        self.modules[i].start();
      }
    }

    this.bootTimeout = setInterval(function() {
      self.api("boot", {}, function(err, returnData) {
        if (!self.bootToken) {
          self.bootToken = returnData.bootToken;
          clearInterval(self.bootTimeout);
        }

        if (!self.instance) {
          self.instance = returnData.instance;
        }
      });
    }, 1000);

    this.eventCheckInterval = setInterval(function() {
      self.api("message/check", {}, function(err) {
        if (err == 'error') {
          // Lost connection to server
          mediator.trigger("icon:show", "connectionError");
        } else {
          mediator.trigger("icon:hide", "connectionError");
        }
      });
    }, 2000);
  }
});

var mediator = new MediatorClass();

module.exports = mediator;