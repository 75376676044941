var Backbone = require('backbone');
var mediator = require('../mediator');

module.exports = Backbone.Model.extend({

  alter: {
    controllers: function (controllers) {
      for (var i in this.controllers) {
        controllers[i] = this.controllers[i];
      }
    },

    dashboard: function (data) {
      data.devices = {
        fa: 'fa-calculator',
        page: 'dashboard/devices',
        text: 'Devices',
        weight: 400
      };
    },

    routes: function (routes) {
      routes['dashboard/devices'] = {
        controller: 'dashboardDevices',
        data: {
          title: "Devices"
        },
        name: "dashboardDevices"
      };
    }
  },

  controllers: {
    dashboardDevices: function ($el, data) {
      var PageView = require('../views/dashboardDevices');
      var view = new PageView(data);

      return view;
    }
  }
});