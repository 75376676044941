var BaseView = require('./base');
var Backbone = require('backbone');
var jQuery = require('jquery');
var mediator = require('../mediator');
var async = require('async');

var views = {
  dashboardMiniCurrent: require('./dashboardMiniCurrent'),
  dashboardMiniInternet: require('./dashboardMiniInternet'),
  dashboardMiniReport: require('./dashboardMiniReport')
};

module.exports = BaseView.extend({

  afterRender: function () {
    var self = this;

    this.$el.find('.items span.columns:last')
        .addClass('end');

    async.each(this.collection.models, function (item, next) {
      if (item.has('view')) {
        var view = new views[item.get('view')]();
        self.$el.find("[data-page=\"" + item.get('page') + "\"] .miniView")
            .empty()
            .append(view.$el);
        view.render();
        next();
      } else {
        next();
      }
    });
  },

  beforeRender: function () {

    // Get all dashboard items
    var items = mediator.alter('dashboard');

    // Convert to array to push into collection
    var list = [];

    for (var i in items) {
      list.push(items[i]);
    }

    this.collection.reset(list);

    // Use collection to sort by weight
    this.collection.sort();

    // Pull from collection in sorted order
    this.options.items = [];

    for (i in this.collection.models) {
      this.options.items.push(this.collection.models[i].attributes);
    }
  },

  events: {
    "click span.item": function (e) {
      var page = jQuery(e.currentTarget)
          .attr('data-page');
      mediator.trigger("page:show", page);
    }
  },

  initialize: function () {
    this.collection = new Backbone.Collection();
    this.collection.comparator = 'weight';
  },

  template: 'dashboard.mustache'
});