var Backbone = require('backbone');
var jQuery = require('jquery');
var mediator = require('../mediator');

module.exports = Backbone.Model.extend({

  alter: {
    account: function(data) {
      for (var i in this.attributes) {
        data[i] = this.attributes[i];
      }
    },

    controllers: function(controllers) {
      for (var i in this.controllers) {
        controllers[i] = this.controllers[i];
      }
    },

    routes: function(routes) {
      routes.login = {
        controller: 'login',
        data: {},
        name: 'login'
      };
    }
  },

  attachEvents: function() {

    var self = this;
    this.listenTo(mediator, 'account:login', function(data, callback) {

      self.login(data.username, data.password, function(err, returnData) {
        callback(err, returnData);
      });
    });

    this.listenTo(mediator, 'icon:show', function(icon, callback) {
      jQuery("#icons ." + icon)
          .show();
    });

    this.listenTo(mediator, 'icon:hide', function(icon, callback) {
      jQuery("#icons ." + icon)
          .hide();
    });

    this.listenTo(mediator, 'restart', function(data, callback) {
      clearInterval(mediator.eventCheckInterval);
      setTimeout(function() {
        window.location.reload();
      }, 5000);
    });
  },

  controllers: {
    login: function($el, data) {
      var PageView = require('../views/loginPage');
      var view = new PageView();

      return view;
    }
  },

  hasPermission: function(permission) {
    if (this.has('permissions') === false) {
      return false;
    }

    var permissions = this.get('permissions');

    if (!permissions || permissions.indexOf(permission) == -1) {
      return false;
    }

    return true;
  },

  login: function(username, password, callback) {
    mediator.log('Logging in...');
    var self = this;

    mediator.api('account/login', {
      username: username,
      password: password
    }, function(err, returnData) {

      if (err) {
        mediator.trigger("toast:warning", '{{ t "' + err + '" }}');

        return;
      }

      self.set(returnData);

      mediator.trigger("toast:success", '{{t "account.loginSuccess" }}');
      mediator.trigger("page:home");
      jQuery('#mainMenu')
          .show();
      jQuery('footer')
          .show();

      callback(err, returnData);
    });
  }

});