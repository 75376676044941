var Backbone = require('backbone');
var mediator = require('../mediator');

module.exports = Backbone.Model.extend({
  attachEvents: function () {

    var self = this;
    this.listenTo(mediator, 'log', function () {
      self.info.apply(self, arguments);
    });

    this.listenTo(mediator, 'error', function () {
      self.error.apply(self, arguments);
    });
  },

  error: function () {
    console.error.apply(console, arguments);
  },

  info: function () {
    console.log.apply(console, arguments);
  },

  mediatorAlter: function () {
    mediator.log = this.info;
    mediator.error = this.error;
  }

});