var BaseView = require('./base');
var mediator = require('../mediator');

module.exports = BaseView.extend({

  afterRender: function () {
  },

  beforeRender: function () {
  },

  events: {
    "click button.generate": function () {
      mediator.api("report/generate", {}, function (err, returnData) {
        if (err) {
          mediator.trigger("toast:warning", '{{ t "' + err + '" }}');

          return;
        }

        mediator.trigger("toast:success", 'Starting report generation...');
      });
    },

    "click button.generateAll": function () {
      mediator.trigger("modal:confirm", {
        text: 'This make take some time and the active screening while change several times. When finished there will be no screening active. Are you sure?',
        title: 'Generate All Reports',
        buttons: [
          'Generate',
          'Cancel'
        ]
      }, function (err, buttonText) {

        if (buttonText === "Generate") {
          mediator.api("report/generateAll", {}, function (err, returnData) {
            if (err) {
              mediator.trigger("toast:warning", '{{ t "' + err + '" }}');

              return;
            }

            mediator.trigger("toast:success", 'Starting all report generation...');
          });
        }
      });
    }
  },

  template: 'dashboardReports.mustache'
});