var BaseView = require('./base');
var mediator = require('../mediator');
var InputView = require('./input');

module.exports = BaseView.extend({
  beforeRender: function() {
    //mediator.trigger("icon:show", "decryption");
  },

  events: {
    "click button": function(e) {
      var username = this.$el.find('[name=username]')
          .val();
      var password = this.$el.find('[name=password]')
          .val();
      mediator.trigger('account:login', {
        username: username,
        password: password
      }, function(err, returnData) {
        // TODO: Shake with error
      });
    }
  },

  initialize: function() {
    var self = this;
  },

  suspend: function() {
    clearTimeout(this.timeout);
  },

  template: 'loginPage.mustache',

  views: {
    ".username": new InputView({
      autocorrect: "off",
      autocapitalize: "off",
      default: "",
      faIcon: "",
      label: '',
      name: "username",
      placeholder: "Username",
      required: false,
      type: "text",
      value: ""
    }),
    ".password": new InputView({
      autocorrect: "off",
      default: "",
      faIcon: "",
      label: '',
      name: "password",
      placeholder: "Password",
      required: false,
      type: "password",
      value: ""
    })
  }
});