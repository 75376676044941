var mediator = require('./mediator');
var jQuery = require('jquery');
var Backbone = require('backbone');

// Our customer module needs access to these items, and currently only way is
// to pass them through the Window object.
window.mediator = mediator;
window.jQuery = jQuery;
window.Backbone = Backbone;

// TODO: Find way to have this list be dynamic
// TODO: Find how to switch connector in
var moduleClasses = {
  account: require('./modules/account'),
  console: require('./modules/console'),
  connectorHTTP: require('./modules/connectors/http'),
  connectorLocal: require('./modules/connectors/local'),
  dashboard: require('./modules/dashboard'),
  debug: require('./modules/debug'),
  device: require('./modules/device'),
  eligibilityFile: require('./modules/eligibilityFile'),
  help: require('./modules/help'),
  i18n: require('./modules/i18n'),
  job: require('./modules/job'),
  modal: require('./modules/modal'),
  participant: require('./modules/participant'),
  report: require('./modules/report'),
  router: require('./modules/router'),
  screening: require('./modules/screening'),
  template: require('./modules/template'),
  toast: require('./modules/toast'),
  version: require('./modules/version')
};


function startBatteryListener() {
  var warnedBatteryLow = false;
  if (!navigator.getBattery) {
    return;
  }

  navigator.getBattery()
    .then(function (battery) {

      var batteryThreshold = 0.25;

      function update() {
        if (!warnedBatteryLow) {
          if (battery.level < batteryThreshold) {
            mediator.trigger("icon:show", "battery1");
            mediator.trigger("toast:error", "Battery is low.");
            warnedBatteryLow = true;
          }
        } else {
          if (battery.level >= batteryThreshold) {
            mediator.trigger("icon:hide", "battery1");
            mediator.trigger("toast:error", "Battery is low.");
            warnedBatteryLow = false;
          }
        }

        if (battery.charging) {
          jQuery('#icons .battery1 i')
            .css("color", "#0bb90b");
        } else {
          jQuery('#icons .battery1 i')
            .css("color", "red");
        }
        console.log("Battery charging? " +
          (battery.charging ? "Yes" : "No"));
        console.log("Battery level: " +
          battery.level * 100 + "%");
        console.log("Battery charging time: " +
          battery.chargingTime + " seconds");
        console.log("Battery discharging time: " +
          battery.dischargingTime + " seconds");
      }

      battery.addEventListener('chargingchange', function () {
        update();
      });

      battery.addEventListener('chargingtimechange', function () {
        update();
      });

      battery.addEventListener('dischargingtimechange', function () {
        update();
      });

      battery.addEventListener('levelchange', function () {
        update();
      });

      update();
    });
}

jQuery(document)
  .on('ready', function () {

    mediator.loadConfig()
      .then(function () {

        mediator.loadModule('logging', moduleClasses[mediator.get('logging')]);
        mediator.loadModule('account', moduleClasses.account);
        mediator.loadModule('connectorHTTP', moduleClasses.connectorHTTP);
        mediator.loadModule('dashboard', moduleClasses.dashboard);
        mediator.loadModule('device', moduleClasses.device);
        mediator.loadModule('eligibilityFile', moduleClasses.eligibilityFile);
        mediator.loadModule('help', moduleClasses.help);
        mediator.loadModule('i18n', moduleClasses.i18n);
        mediator.loadModule('job', moduleClasses.job);
        mediator.loadModule('modal', moduleClasses.modal);
        mediator.loadModule('participant', moduleClasses.participant);
        mediator.loadModule('report', moduleClasses.report);
        mediator.loadModule('router', moduleClasses.router);
        mediator.loadModule('screening', moduleClasses.screening);
        mediator.loadModule('toast', moduleClasses.toast);
        mediator.loadModule('template', moduleClasses.template);
        mediator.loadModule('version', moduleClasses.version);

        // We have to load customer module dynamically as it cant be
        // bundled in with app.
        mediator.loadModule('customer', window.customer);

        mediator.start();
        startBatteryListener();
      });

  });
