var BaseView = require('./base');
var mediator = require('../mediator');

module.exports = BaseView.extend({

  afterRender: function () {
    var self = this;
    clearInterval(this.interval);
    this.interval = setInterval(function () {
      self.update();
    }, 5000);
  },

  beforeRender: function () {
    this.options.screening = mediator.alter('screening');
    this.options.participant = mediator.alter('participant');
    this.options.version = mediator.alter('version');
  },

  events: {
    'click button[name=add]': function () {
      var ssid = this.$el.find('[name=ssid]').val();
      var passphrase = this.$el.find('[name=passphrase]').val();
      mediator.api('internet/wifi', {
        ssid: ssid,
        passphrase: passphrase
      }, function (err, returnData) {
        if (err) {
          mediator.trigger('toast:warning', '{{ t "' + err + '" }}');

          return;
        }

        mediator.trigger('toast:info', 'Updated address book.');
      });
    }
  },

  suspend: function () {
    clearInterval(this.interval);
  },

  template: 'dashboardInternet.mustache',

  update: function () {
    var self = this;
    mediator.api('internet/status', {
    }, function (err, returnData) {
      self.options.internet = returnData;

      if (self.options.internet.connected) {
        self.options.internet.status = 'Connected';
      } else {
        self.options.internet.status = 'Not Connected';
      }

      if (self.options.internet.addressBook.length>0) {
        self.options.internet.addressBook = '<ul><li>' + self.options.internet.addressBook.join('</li><li>') + '</li></ul>';
      } else {
        self.options.internet.addressBook = '';
      }

      for (var i in self.options.internet) {
        self.$el.find('[internet=' + i + ']').html(self.options.internet[i]);
      }
    });
  }
});