var BaseView = require('./base');
var mediator = require('../mediator');

module.exports = BaseView.extend({
  afterRender: function() {
  },

  beforeRender: function() {
    this.options.version = mediator.alter('version');
    if (mediator.instance) {
      var temp = mediator.instance.split(".");
      if (temp[1]) {
        this.options.instance = temp[1];
      } else {
        this.options.instance = mediator.instance;
      }
    } else {
      this.options.instance = "";
    }
  },

  events: {},

  setScreening: function(data) {
    this.options.screening = data;
    this.render();
  },

  template: 'footer.mustache'
});