var BaseView = require('./base');
var Backbone = require('backbone');
var jQuery = require('jquery');
var mediator = require('../mediator');

var InputView = require('./input');
var ParticipantSearchListItem = require('./participantSearchListItem');
var ParticipantList = Backbone.Collection.extend();

module.exports = BaseView.extend({

  afterRender: function () {
    if (!this.config.add) {
      this.$el.find('button[data-add]')
        .hide();
    }
    if (this.config.mode) {
      this.update();
    }
  },

  beforeRender: function () {

    // TODO: Hacky workaround to prevent search box from disappearing
    if (!this.views['.search']) {
      this.setView(".search", new InputView({
        autofocus: true,
        type: "search"
      }));
    }
  },

  events: {
    "click [data-add]": function () {
      window.location.hash = '#participantEdit';
    },

    "keyup input": function (e) {
      var self = this;
      var val = jQuery(e.currentTarget)
        .val();

      if (this.timeout) {
        clearTimeout(this.timeout);
      }

      (function (self, val, elem) {
        self.timeout = setTimeout(function () {
          if (jQuery(elem)
            .val() === val) {
            self.update();
          }
        }, 300);
      })(this, val, e.currentTarget);
    }
  },

  initialize: function () {
    var self = this;
    this.list = new ParticipantList();
    this.list.on('reset', function (a, b, c) {

      if (this.length === 0) {
        self.$el.find('.noResults')
          .show();
      } else {
        self.$el.find('.noResults')
          .hide();
      }

      // Trigger redraw
      var $el = self.$el.find('.list');
      self.removeView(".list");

      for (var i in this.models) {

        // Map status to its own value for templating
        this.models[i].set(this.models[i].get('status'), true);
        self.insertView(".list", new ParticipantSearchListItem(this.models[i].attributes));
      }

      self.getViews(".list")
        .each(function (view) {
          self.listenTo(view, "click", function (participantID) {
            mediator.trigger('participant:load', participantID, function () {
              mediator.trigger('page:next');
            });
          });

          view.render();
        });

    });
  },

  refresh: function () {
    mediator.trigger("participant:clear");
  },

  template: 'participantSearch.mustache',

  update: function () {
    var self = this;
    var mode = 'full';
    if (this.config.mode) {
      mode = this.config.mode;
    }

    mediator.api("participant/search", {
      mode: mode,
      terms: self.views['.search'].val()
        .split(' ')
    }, function (err, returnData) {

      if (err) {
        mediator.trigger("toast:warning", '{{ t "' + err + '" }}');
        self.list.reset([]);

        return;
      }

      self.list.reset(returnData);
    });
  }

});