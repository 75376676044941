var Backbone = require('backbone');
var HelpView = require('../views/help');
var mediator = require('../mediator');

var pageMap = {
  "#login": "User_Login.html",
  "#participantSelect": "Selecting_A_Participant.html",
  "#dashboard/current": "Managing_The_Current_Screening.html",
  "#dashboard/screenings": "Managing_Screenings.html",
  "#dashboard/reports": "Managing_Reports.html",
  "#dashboard/devices": "Managing_Wireless_Devices.html",
  "#dashboard/internet": "Internet_Status.html",
  "#dashboard/jobs": "Managing_Wireless_Device_Jobs.html",
  "#dashboard/eligibilityFiles": "Managing_Eligibility_Files.html",
  "#dashboard/status": "Server_Status.html"
};

module.exports = Backbone.Model.extend({

  start: function () {

    var self = this;
    // Band-aid due to race conditions in rendering
    setTimeout(function () {
      mediator.trigger("icon:show", "help");
    }, 1000);

    jQuery("#icons .help")
      .click(function () {
        var page = "Overview.html";
        if (pageMap[window.location.hash]) {
          page = pageMap[window.location.hash];
        }
        mediator.trigger("modal:view", {
          title: "Help",
          size: "large",
          view: new HelpView({
            page: page
          })
        });
      });
  }

});