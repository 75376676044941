var BaseView = require('./base');
var HeaderView = require('./header');
var QuestionView = require('./question');
var mediator = require('../mediator');

module.exports = BaseView.extend({

  afterRender: function () {
  },

  beforeRender: function () {

    var self = this;
    self.removeView(".questions");

    for (var i in self.options.config.questions) {

      if (self.options.config.questions[i].header) {
        self.insertView('.questions', new HeaderView(self.options.config.questions[i]));
      } else if (self.options.config.questions[i].question) {
        self.insertView('.questions', new QuestionView(self.options.config.questions[i]));
      }
    }
  },

  events: {},

  initialize: function () {

    var self = this;

    // TODO: Find better way to do this
    this.listenTo(mediator, "response:change", function () {

      for (var i in self.views['.questions']) {
        if (self.views['.questions'][i].checkCondition) {
          self.views['.questions'][i].checkCondition();
        }
      }
    });
  },

  template: 'questionPage.mustache',
  validate: function () {
    var validate = true;

    for (var i in this.views['.questions']) {

      if (this.views['.questions'][i].validate) {

        if(this.views['.questions'][i].checkCondition() === true) {
          if (this.views['.questions'][i].validate() === false) {
            validate = false;
          }
        }
      }
    }

    return validate;
  }
});