var BaseView = require('./base');
var mediator = require('../mediator');

module.exports = BaseView.extend({

  initialize: function() {

    var self = this;
    this.listenTo(mediator, "response:change:" + this.options.name, function(data) {
      self.options.response = data;
      self.render();
    });
  },

  setValue: function (val) {
    var self = this;

    // Dont do anything unless value has changed
    if ((this.options.response &&
        val === this.options.response.response) ||
        this.pending === true) {

      return;
    }

    this.$el.find('.pending').css('visibility', 'visible');

    var participant = mediator.alter('participant');
    this.pending = true;
    mediator.api("participant/response/update/" + participant.participantID + "/" + this.options.question.name, {
      response: val
    }, function (err, returnData) {
      setTimeout(function () {
        self.pending = false;
        self.$el.find('.pending').css('visibility', 'hidden');
        mediator.trigger("response:set", returnData);
        self.options.response = returnData;
        self.render();
      }, 0);

      if (err) {

        return;
      }
    });
  },

  validate: function () {
    
    if (this.pending === true) {
      mediator.trigger("toast:warning",
          "Please wait, your response for \"" + this.options.question.title + "\" has not been saved yet.");

      return false;
    }

    if (this.options.question.required === true) {

      if (!this.val()) {
        this.$el.addClass('invalid');
        this.shake();
        mediator.trigger("toast:warning", "Please enter a response for \"" + this.options.question.title + "\".");

        return false;
      }
    }

    if (this.options.response.status === "invalid") {
      this.$el.addClass('invalid');
      this.shake();
      mediator.trigger("toast:warning", "Please enter a valid response for \"" + this.options.question.title + "\".");

      return false;
    }

    return true;
  },

  val: function () {

    return this.options.response.response;
  }

});