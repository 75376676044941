var Backbone = require('backbone');
var mediator = require('../mediator');
var jQuery = require('jquery');
var ConfirmView = require('../views/confirm');

module.exports = Backbone.Model.extend({

  attachEvents: function () {

    var self = this;

    this.listenTo(mediator, "modal:confirm", function (data, callback) {
      self.confirm(data, callback);
    });

    this.listenTo(mediator, "modal:close", function () {
      self.$modal.foundation('close');
      if (self.view.suspend) {
        self.view.suspend();
      }
      delete(self.view);
    });

    this.listenTo(mediator, "modal:view", function (data, callback) {
      self.viewSet(data, callback);
    });
  },

  confirm: function (options, callback) {

    var title = 'Confirm action';
    if (options.title) {
      title = options.title;
    }
    this.viewSet({
      title: title,
      view: new ConfirmView(options)
    }, callback);
  },

  start: function () {
    var self = this;
    jQuery("body")
      .append('<div id="modal" class="reveal" data-reveal><h3></h3><div class="view"></div></div>');
    this.$modal = jQuery("#modal");
    this.modal = new Foundation.Reveal(this.$modal);
    this.$modal.on('closed.zf.reveal', function () {
      mediator.trigger("modal:close");
    });
  },

  viewSet: function (options, callback) {

    var self = this;
    this.view = options.view;
    this.$modal.find('h3')
      .html(options.title);
    this.$modal.find(".view")
      .empty()
      .append(this.view.$el);
    this.view.render();
    this.$modal
      .removeClass("tiny")
      .removeClass("small")
      .removeClass("large")
      .removeClass("full");
    if (options.size) {
      this.$modal.addClass(options.size);
    }
    this.$modal.foundation('open');
    this.view.on("close", function () {
      self.$modal.foundation('close');
    });

    this.view.on("callback", function (data) {
      callback(null, data);
    });
  }

});