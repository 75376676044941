var BaseView = require('./base');
var mediator = require('../mediator');

module.exports = BaseView.extend({

  events: {
    "click .edit": function () {
      window.location.hash = "#dashboard/screeningEdit/" + this.options.screeningID;
    },

    "click .activate": function () {
      mediator.api("screening/activate", {
        screeningID: this.options.screeningID
      }, function (err) {

        if (err) {
          mediator.trigger("toast:warning", '{{ t "' + err + '" }}');
        }
      });
    },

    "click .delete": function () {

      var self = this;

      var screening = mediator.alter('screening');

      if (screening.screeningID === this.options.screeningID) {

        mediator.trigger("toast:warning", '{{ t "screening.cannotDeleteActive" }}');

        return;
      }

      mediator.trigger("modal:confirm", {
        text: 'Are you sure you want to delete this screening?',
        title: 'Confirm screening deletion',
        buttons: [
          'Delete',
          'Cancel'
        ]
      }, function (err, buttonText) {

        if (buttonText === "Delete") {
          mediator.api("screening/delete", {
            screeningID: self.options.screeningID
          }, function (err) {

            if (err) {
              mediator.trigger("toast:warning", '{{ t "' + err + '" }}');

              return;
            }

            mediator.trigger("page:refresh");
          });
        }
      });
    }
  },

  template: 'dashboardScreeningsItem.mustache'
});